import React from "react";

import SiteSearch from "shared/components/siteSearch";

function Home() {
  return (
    <div className="Home">
      <SiteSearch />
    </div>
  );
}

export default Home;
