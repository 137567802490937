import { useCallback, useEffect, useState } from "react";

import appConstants from "shared/constants/app";
import { getScrollPosition } from "shared/helpers/dom";
import { throttle } from "shared/helpers/optimization";
import { T_DocumentTitleParams } from "./types";
import { getDocumentTitle, scrollToTop } from "./helpers";

export function useDocumentTitle(params: T_DocumentTitleParams) {
  const [docTitle, setDocTitle] = useState(getDocumentTitle(params));

  useEffect(() => {
    const newDocTitle = getDocumentTitle(params);
    setDocTitle(newDocTitle);
    document.title = newDocTitle;
  }, [params]);

  return [docTitle, setDocTitle];
}

export function useScrollToTop() {
  const [scrollPosition, setScrollPosition] = useState({ x: 0, y: 0 });
  const handleScroll = () => {
    const scrollPosition = getScrollPosition();
    setScrollPosition(scrollPosition);
  };
  const throttledHandleScroll = throttle(
    handleScroll,
    appConstants.scroll.eventThrottleInterval
  );
  const memoizedThrottledHandleScroll = useCallback(throttledHandleScroll, []);

  useEffect(() => {
    window.addEventListener("scroll", memoizedThrottledHandleScroll);

    return () => {
      window.removeEventListener("scroll", memoizedThrottledHandleScroll);
    };
  }, []);

  const hasSomeScroll =
    scrollPosition.y >= appConstants.scroll.minScrollForScrollToTop;

  return {
    hasSomeScroll,
    scrollToTop
  };
}
