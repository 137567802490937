import { useEffect, useCallback, useReducer } from "react";
import {
  E_SiteSearchActions,
  T_SiteSearchAction,
  T_SiteSearchState
} from "./types";
import { debounce } from "shared/helpers/optimization";
import { fuzzySearch } from "./helpers";

export function useSiteSearch() {
  const [state, dispatch] = useReducer(siteSearchReducer, {
    inputValue: "",
    searchResults: null
  });

  const updateSearchResults = useCallback(
    debounce(
      () => dispatch([E_SiteSearchActions.setSearchResults]),
      200 // Update search results if 200ms passed after user has stopped typing
    ),
    []
  );

  useEffect(() => {
    updateSearchResults();
  }, [state.inputValue, updateSearchResults])

  const handleInputValueChange = useCallback((
    event: React.ChangeEvent<HTMLInputElement> | { target: { value: string }}
  ) => {
    dispatch([E_SiteSearchActions.setInputValue, event.target.value]);
  }, []);

  return {
    ...state,
    handleInputValueChange
  };
}

function siteSearchReducer(
  state: T_SiteSearchState,
  [type, payload]: T_SiteSearchAction
): T_SiteSearchState {
  switch (type) {
    case E_SiteSearchActions.setInputValue: {
      return {
        ...state,
        inputValue: payload
      };
    }
    case E_SiteSearchActions.setSearchResults: {
      const inputValueStr = String(state.inputValue);
      const newSearchResults = fuzzySearch.search(
        inputValueStr.replace(/\s+/g, "")
      );

      return {
        ...state,
        searchResults: newSearchResults
      };
    }
    default:
      return state;
  }
}
