import { useMemo, createRef } from "react";
import { T_NullableAndVoidable } from "shared/types/generics";

export function useDynamicRefList<T>(
  dataArray: T_NullableAndVoidable<unknown[]>
) {
  return useMemo(
    () => (Array.isArray(dataArray) ? dataArray : []).map(() => createRef<T>()),
    [dataArray]
  );
}
