import { useMemo } from "react";
import { T_SuperConcatArgs } from "shared/helpers/string/types";
import { cssClass } from "shared/helpers/styles";

export function useMemoizedCSSClass(classArgs: T_SuperConcatArgs, deps: unknown[]) {
  return useMemo(
    () => cssClass(...classArgs),
    // We explicitly receive set of dependencies to
    // eslint-disable-next-line react-hooks/exhaustive-deps
    deps
  );
}
