import React from "react";

import "./styles.scss";

function Loading() {
  return (
    <div className="Loading">
      <div className="py-2 px-5 rounded-lg flex items-center flex-col">
        <div className="loaderDots block relative w-20 h-5 mt-2">
          <div className="dot"></div>
          <div className="dot"></div>
          <div className="dot"></div>
          <div className="dot"></div>
        </div>
        <div className="text-gray-800 text-xs font-mono mt-5 text-center">
          Downloading and rendering stuff...
        </div>
      </div>
    </div>
  );
}

export default Loading;
