import lazyComponent from "shared/components/lazyComponent";
import appRoutes from "shared/constants/appRoutes";

const appRoutesToComponentsMap: {
  path: string;
  Component: ReturnType<typeof lazyComponent>;
}[] = [];

for (const [key, value] of Object.entries(appRoutes)) {
  if (key !== "home") {
    // "/tools/money/forex" => "pages/tools/money/forex"
    const pageModulePath = `pages${value.path}`;
    appRoutesToComponentsMap.push({
      path: value.path,
      Component: lazyComponent(pageModulePath)
    });
  }
}

export default appRoutesToComponentsMap;
