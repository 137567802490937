import windowConstants from "shared/constants/window";
import { T_Breakpoint, T_BreakpointName, T_BreakpointValue } from "shared/types/window";

export function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

export function getActiveBreakpoint(width: number): T_Breakpoint {
  let activeBreakpointValue = windowConstants.breakpoints.none.value as T_BreakpointValue;
  let activeBreakpointName = windowConstants.breakpoints.none.name as T_BreakpointName;

  for (let { value, name } of Object.values(windowConstants.breakpoints)) {
    if (width >= value && value >= activeBreakpointValue) {
      activeBreakpointValue = value;
      activeBreakpointName = name;
    }
  }

  return {
    value: activeBreakpointValue,
    name: activeBreakpointName
  };
}
