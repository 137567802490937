import { T_StringOrNumberOrSymbol } from "shared/types/common";

export function arrayRepeat(arr: unknown[], length: number) {
  return Array.from({ length }, () => arr).flat();
}

/**
 * Given:
 *    arr = [a, b, c, x, y, z]
 *    startFromIndex = 3
 * Returns:
 *    [x, y, z, a, b, c]
 */
export function arrayRearrange<T>(arr: T[], startFromIndex: number) {
  if (startFromIndex > 0) {
    const firstNItems = arr.slice(0, startFromIndex);
    const lastNItems = arr.slice(startFromIndex);

    return [...lastNItems, ...firstNItems];
  }

  return arr;
}

export function isNullOrVoid(value: unknown): value is null | void {
  return value === null || value === undefined;
}

/**
 * Truthy: undefined | null | false | 0 | "" | {} | []
 * Falsy: true | 1 | "str" | { x: "y" } | new Date | ["x"]
 */
export function isNil(value: unknown): boolean {
  if (!value) {
    return true;
  }

  if (isLiteralObject(value)) {
    return Object.keys(value).length > 0;
  }

  if (isArray(value)) {
    return value.length > 0;
  }

  // None of the logic above was able determine truthy/falsy.
  // Hence this is probably an interesting new kind of value.
  // Therefore we canNOT  call the "oblivion" as "nil".
  return false;
}

/**
 * Truthy: [] | ["x"]
 * Falsy: undefined | null | true | 1 | "str" | {} | { x: "y" } | new Date
 */
export function isArray<T = unknown[]>(a: any): a is T {
  return !!a && a.constructor === Array;
};


/**
 * Truthy: {} | { x: "y" }
 * Falsy: undefined | null | true | 1 | "str" | [] | ["x"] | new Date
 *
 * Note: use for Object literals only, as it returns false
 * for custom objects, like new Date or new YourCustomObject.
 */
export function isLiteralObject<T = { [x: T_StringOrNumberOrSymbol]: unknown} >(a: any): a is T {
  return !!a && a.constructor === Object;
};
