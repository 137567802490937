import BreadCrumb from "shared/components/breadcrumb";
import React, { useEffect, useMemo, useRef } from "react";
import { useNavigate } from "react-router-dom";

import { useDynamicRefList } from "shared/hooks/dom";
import {
  useArrowKeysToFocusElement,
  useIsKeyPressed
} from "shared/hooks/browserEvent";
import { useSiteSearch } from "./hooks";
import "./styles.scss";
import { E_KeyboardKey } from "../keyboardKey/types";
import KeyboardKey from "../keyboardKey";
import windowConstants from "shared/constants/window";
import { useActiveBreakpoint } from "shared/hooks/window";
import { E_KeyboardEventKey } from "shared/types/browserEvent";

function SiteSearch() {
  const navigate = useNavigate();
  const { inputValue, searchResults, handleInputValueChange } = useSiteSearch();
  const activeBreakpoint = useActiveBreakpoint();
  const isKeyboardControlsEnabled =
    activeBreakpoint.value >= windowConstants.breakpoints.extraLarge.value;
  const searchInputRef = useRef<HTMLInputElement>(null);
  const searchResultsRefList = useDynamicRefList<HTMLDivElement>(searchResults);
  const focusTargetElements = useMemo(
    () => [searchInputRef, ...searchResultsRefList],
    [searchResultsRefList]
  );
  const { setActiveElementIndex } = useArrowKeysToFocusElement<
    HTMLDivElement | HTMLInputElement
  >(focusTargetElements, {
    initActiveElementIndex: 0,
    isUpAndDownOnly: true,
    isKeyListenerDisabled: !isKeyboardControlsEnabled
  });
  const isDeletePressed = useIsKeyPressed(
    E_KeyboardEventKey.delete,
    !inputValue
  );
  useEffect(() => {
    if (isDeletePressed) {
      handleInputValueChange({ target: { value: "" } });
      searchInputRef.current?.focus();
      setActiveElementIndex(0);
    }
  }, [isDeletePressed]);

  return (
    <div className="siteSearch">
      <div className="searchInputWrap">
        <input
          autoFocus
          ref={searchInputRef}
          type="text"
          className="searchInput"
          placeholder="what ye lookin' for ?"
          value={inputValue}
          onChange={handleInputValueChange}
        />
        {isKeyboardControlsEnabled && (
          <div className="text-center text-gray-500 font-mono text-xs italic opacity-60">
            <KeyboardKey keyboardKey={E_KeyboardKey.upArrow} />
            <KeyboardKey keyboardKey={E_KeyboardKey.downArrow} />
            &nbsp;choose&emsp;—&emsp;
            <KeyboardKey keyboardKey={E_KeyboardKey.enter} />
            &nbsp;go to&emsp;—&emsp;
            <KeyboardKey keyboardKey={E_KeyboardKey.delete} />
            &nbsp;clear search
          </div>
        )}
      </div>
      <div className="searchResultsWrap">
        {searchResults &&
          searchResults.map(({ item }, index) => {
            return (
              <div
                ref={searchResultsRefList[index]}
                key={item.title}
                tabIndex={0}
                className="searchResultItemWrap"
                onClick={() => navigate(item.path)}
                onKeyPress={(event: React.KeyboardEvent) => {
                  if (event.key === "Enter") {
                    navigate(item.path);
                  }
                }}
              >
                <span className="font-medium tracking-wide">{item.title}</span>
                <BreadCrumb
                  path={item.path}
                  crumbSeparatorClassName="!w-4 !h-4"
                  crumbClassName="!text-2xs sm:!text-xs !text-gray-400"
                  wrapperClassName="!-my-1 !px-0"
                  hideHome
                  makeCrumbsUnreachableViaKeyNav
                />
              </div>
            );
          })}
      </div>
    </div>
  );
}

export default SiteSearch;
