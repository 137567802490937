import Fuse from "fuse.js";

import appRoutes from "shared/constants/appRoutes";
import { T_Nullable, T_ObjectItem } from "shared/types/generics";
import { T_StringOrNumber } from "shared/types/common";

export enum E_SiteSearchActions {
  setInputValue,
  setSearchResults
}

export type T_SiteSearchState = {
  inputValue: T_StringOrNumber;
  searchResults: T_Nullable<
    Fuse.FuseResult<T_ObjectItem<typeof appRoutes>>[]
  >;
};

export type T_SiteSearchAction = [type: E_SiteSearchActions, payload?: any];
