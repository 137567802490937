// import { T_Nullable } from "types/generic";

// export function debounce<T extends unknown[]>(
//   func: (...args: T) => void,
//   delay: number
// ): (...args: T) => void {
//   let timer: T_Nullable<ReturnType<typeof setTimeout>> = null;
//   return (...args: T) => {
//     if (timer) clearTimeout(timer);
//     timer = setTimeout(() => {
//       func.call(null, ...args);
//     }, delay);
//   };
// }

export function debounce<T extends unknown[], U>(
  callback: (...args: T) => PromiseLike<U> | U,
  wait: number
) {
  let timer: ReturnType<typeof setTimeout>;

  return (...args: T): Promise<U> => {
    clearTimeout(timer);
    return new Promise((resolve) => {
      timer = setTimeout(() => resolve(callback(...args)), wait);
    });
  };
}

/**
 * Returns a function, that, when invoked, will only be triggered at
 * most once during a given window of time.
 */
export const throttle = (func: any, wait: number) => {
  let lastFunc: any;
  let lastRan: number;
  return (...args: any[]) => {
    if (!lastRan) {
      func.apply(null, args);
      lastRan = Date.now();
    } else {
      clearTimeout(lastFunc);
      lastFunc = setTimeout(() => {
        if (Date.now() - lastRan >= wait) {
          func.apply(null, args);
          lastRan = Date.now();
        }
      }, wait - (Date.now() - lastRan));
    }
  };
};
