const appConstants = {
  API: {
    forex: process.env.REACT_APP_API_FOREX ?? ""
  },
  time: {
    milliSecondsPerDay: 86400000
  },
  webStorage: {
    forexData: "forexData",
    forexInputCode: "forexInputCode",
    forexTargetCode: "forexTargetCode"
  },
  scroll: {
    minScrollForScrollToTop: 100, // pixels
    eventThrottleInterval: 100, // milliseconds
  },
  search: {
    inputDebounceInterval: 200
  },
  responsive: {
    activeBreakpointComputeInterval: 100
  }
} as const;

export default appConstants;
