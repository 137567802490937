import React from "react";

import { useMemoizedCSSClass } from "shared/hooks/styles";
import { T_KeyboarKeyProps } from "./types";

function KeyboardKey(props: T_KeyboarKeyProps) {
  const keyClass = useMemoizedCSSClass(
    [
      "keyboardKey inline bg-gray-100 mx-0.5 px-1.5 py-0.5 shadow-sm border border-gray-400 rounded-[4px] text-xs text-gray-800 whitespace-nowrap not-italic",
      props.className
    ],
    [props.className]
  );

  return <kbd className={keyClass}>{props.keyboardKey}</kbd>;
}

export default KeyboardKey;
