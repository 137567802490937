import { E_JavaScriptLang } from "shared/types/common";
import { isArray, isLiteralObject, isNil } from "../dataStructure";
import { T_ConcatArgPrimitive, T_SuperConcatArgs } from "./types";

export function toTitleCase(str: string) {
  return str
    .replace(/-/g, " ")
    .replace(/\s+/g, " ")
    .replace(/[a-zA-Z]+/g, (word) => word[0].toUpperCase() + word.slice(1))
    .replace(/([A-Z]+)/g, " $1") // "AbcXyz" => " Abc Xyz"
    .replace(/^\s+|\s+$/g, ""); // " Abc Xyz " => "Abc Xyz"
}

export function toCamelCase(text: string): string {
  return text
    .replace(/(?:^\w|[A-Z]|\b\w)/g, (leftTrim: string, index: number) =>
      index === 0 ? leftTrim.toLowerCase() : leftTrim.toUpperCase()
    )
    .replace(/\s+/g, "");
};

export function toHTMLElementID(str: string) {
  return str.replace(/[^a-zA-Z0-9-_]+/g, "-")
}

export function getLastPart(str: string, separator: string) {
  return str.split(separator).pop() ?? "";
}

export function getLastPartInTitleCase(str: string, separator: string) {
  return toTitleCase(getLastPart(str, separator));
}

export function pluralize(word: string, count: number) {
  return `${count} ${word}${count > 1 ? "s" : ""}`;
}

export function concat(
  strArray: T_ConcatArgPrimitive[],
  separator = "",
  predicate: (value: unknown) => boolean = (v) => !isNil(v)
) {
  return strArray.filter(predicate).join(separator);
}

function getSeperatorForIteration(iterationIndex: number, separator: string) {
  return iterationIndex === 0 ? "" : separator;
}

export function superConcat(separator: string, ...args: T_SuperConcatArgs) {
  if (isArray(args)) {
    return args.reduce((mainAcc: string, arg, mainIndex): string => {
      let iterationResult;
      if (arg) {
        if (typeof arg === E_JavaScriptLang.string) {
          iterationResult = arg;
        }

        if (isArray(arg)) {
          iterationResult = concat(arg, separator);
        }

        if (isLiteralObject(arg)) {
          iterationResult = Object.keys(arg).reduce(
            (subAcc, key, subIndex) =>
              arg[key]
                ? `${subAcc}${getSeperatorForIteration(
                    subIndex,
                    separator
                  )}${key}`
                : subAcc,
            ""
          );
        }

        return `${mainAcc}${getSeperatorForIteration(
          mainIndex,
          separator
        )}${iterationResult}`;
      }

      return mainAcc;
    }, "");
  }

  return "";
}
